import React from 'react';

export default () => {
  return (
  <svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0)">
  <path d="M14.0048 6.81097C10.0346 6.81097 6.82043 10.0286 6.82043 13.9953C6.82043 17.9655 10.0381 21.1796 14.0048 21.1796C17.9749 21.1796 21.1891 17.962 21.1891 13.9953C21.1891 10.0251 17.9714 6.81097 14.0048 6.81097V6.81097ZM14.0048 18.6585C11.4276 18.6585 9.3416 16.5713 9.3416 13.9953C9.3416 11.4193 11.4288 9.33214 14.0048 9.33214C16.5808 9.33214 18.6679 11.4193 18.6679 13.9953C18.6691 16.5713 16.5819 18.6585 14.0048 18.6585V18.6585Z" fill="white"/>
  <path d="M19.7727 0.0886224C17.1967 -0.0315441 10.8162 -0.0257108 8.23785 0.0886224C5.97219 0.194789 3.97369 0.741955 2.36252 2.35312C-0.330146 5.04578 0.014021 8.67411 0.014021 13.9953C0.014021 19.4413 -0.289312 22.9856 2.36252 25.6374C5.06569 28.3394 8.74652 27.9859 14.0047 27.9859C19.3994 27.9859 21.2614 27.9894 23.1689 27.2509C25.7624 26.2441 27.72 23.9259 27.9114 19.7621C28.0327 17.1849 28.0257 10.8056 27.9114 8.22728C27.6804 3.31212 25.0425 0.331289 19.7727 0.0886224V0.0886224ZM23.8502 23.8559C22.085 25.6211 19.6362 25.4636 13.9709 25.4636C8.13752 25.4636 5.79835 25.5499 4.09152 23.8384C2.12569 21.8819 2.48152 18.7401 2.48152 13.9766C2.48152 7.53078 1.82002 2.88862 8.28919 2.55729C9.77552 2.50479 10.213 2.48729 13.9545 2.48729L14.007 2.52229C20.2242 2.52229 25.102 1.87129 25.3949 8.33928C25.4614 9.81511 25.4765 10.2584 25.4765 13.9941C25.4754 19.7598 25.585 22.1129 23.8502 23.8559V23.8559Z" fill="white"/>
  <path d="M21.4736 8.20635C22.4008 8.20635 23.1525 7.45472 23.1525 6.52752C23.1525 5.60033 22.4008 4.84869 21.4736 4.84869C20.5464 4.84869 19.7948 5.60033 19.7948 6.52752C19.7948 7.45472 20.5464 8.20635 21.4736 8.20635Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0">
  <rect width="28" height="28" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  )
}
